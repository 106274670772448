import { Box, IconButton, Table, Typography } from "@mui/joy";
import { useParams } from "react-router-dom";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import SimplePageLayout from "../../Layout/SimplePageLayout";
import { useBacktestTask } from "../../api/backtest.api";
import { useRoute } from "../../Layout/router.const";
import { formatDate } from "../../utils";
import { MS_IN_TIMEFRAME } from "../../timeframe";
import {
  calcProfit,
  generateTaskName,
  useNavigateOrderPage,
  useNavigateTaskChartPage,
} from "./maxma.utils";
import MaXMaTaskConclusion from "./MaXMaTaskConclusion";

export default function MaXMaTaskPage() {
  const params = useParams();
  const taskId = +(params.task_id || -1);

  const parentPage = useRoute("MA x MA - Analysis");

  const navigateOrderPage = useNavigateOrderPage();
  const navigateTaskChartPage = useNavigateTaskChartPage();

  const {
    data: { task, orders, analytics },
  } = useBacktestTask(taskId);

  const rightSide = (
    <Box>
      <IconButton onClick={() => navigateTaskChartPage(task.id)}>
        <QueryStatsIcon />
      </IconButton>
    </Box>
  );

  return (
    <SimplePageLayout
      breadcrumbs={[{ text: parentPage.name, href: parentPage.path }]}
      title={generateTaskName(task)}
      rightTitle={rightSide}
      bottomTitle={<MaXMaTaskConclusion task={task} analytics={analytics} />}
    >
      <Table hoverRow>
        <thead>
          <tr>
            <th style={{ width: 32 }}>ID</th>
            <th style={{ width: 50, textAlign: "center" }}>Type</th>
            <th style={{ width: 170 }}>Price</th>
            <th style={{ width: 100 }}>TS</th>
            <th style={{ width: 50 }}>DUR</th>
            <th style={{ width: 50 }}>Profit, %</th>
            <th style={{ width: 64, textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td style={{ textAlign: "center" }}>
                {order.type === "long" ? (
                  <KeyboardDoubleArrowUpIcon />
                ) : (
                  <KeyboardDoubleArrowDownIcon />
                )}
              </td>
              <td>{`${order.entryPrice} => ${order.exitPrice}`}</td>
              <td>{formatDate(order.tsOpen)}</td>
              <td>
                {(order.tsClose - order.tsOpen) /
                  MS_IN_TIMEFRAME[task.timeframe]}
              </td>
              <td>
                <Typography
                  color={calcProfit(order) > 0 ? "success" : "danger"}
                >
                  {calcProfit(order).toFixed(2)} %
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <IconButton onClick={() => navigateOrderPage(order.id)}>
                  <QueryStatsIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </SimplePageLayout>
  );
}
