import { Box, Typography } from "@mui/joy";
import { BacktestOrderEntity } from "../../../../crypto-bulls-backend/src/components/backtest/backtest.entity";
import { calcDeviation, calcProfit } from "./maxma.utils";
import { formatNumber } from "../../utils";

type Props = {
  order: BacktestOrderEntity;
};

export default function MaXMaOrderConclusion({
  order,
  order: { entryPrice, maxPrice, minPrice, exitPrice, type },
}: Props) {
  const maxDeviation = calcDeviation(entryPrice, maxPrice, type === "short");
  const minDeviation = calcDeviation(entryPrice, minPrice, type === "short");
  const profit = calcProfit(order);
  const volatility = (maxPrice / ((minPrice + maxPrice) / 2) - 1) * 100;
  const stopLoss =
    (1 - (type === "long" ? minPrice / entryPrice : entryPrice / maxPrice)) *
    100;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography level="body-sm">Entry: {entryPrice}</Typography>
        <Typography level="body-sm">
          Max: {maxPrice}({maxDeviation.toFixed(2)}%)
        </Typography>
        <Typography level="body-sm">
          Min: {minPrice}({minDeviation.toFixed(2)}%)
        </Typography>
        <Typography level="body-sm">
          Exit: {exitPrice}({profit.toFixed(2)}%)
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography level="body-sm">
          StopLoss: {stopLoss.toFixed(2)}%
        </Typography>
        <Typography level="body-sm">
          Volatility: {volatility.toFixed(2)}%
        </Typography>
        <Typography
          level="body-sm"
          fontWeight={600}
          color={profit < 0 ? "danger" : "success"}
        >
          Profit: {formatNumber(profit.toFixed(2))}%
        </Typography>
      </Box>
    </Box>
  );
}
