import { useMemo } from "react";
import { ApexOptions } from "apexcharts";
import {
  ApexChartCandleData,
  ApexChartLineData,
  ApexChartSeriesData,
  SeriesChartProps,
  ShapeChartProps,
} from "./chart.type";
import {
  DEFAULT_CHART_OPTIONS,
  VERTICAL_CHART_OFFSET,
  VOLUME_CHART_HEIGHT,
} from "./chart.const";
import { SHAPES } from "./chart.shapes";
import { useColorScheme } from "@mui/joy";

export const useDefaultOptions = (): ApexOptions => DEFAULT_CHART_OPTIONS;

export const useSeriesOptions = (
  series: SeriesChartProps[],
  spliceLeft: number,
): ApexOptions =>
  useMemo(() => {
    // data calculations
    const seriesData: ApexChartSeriesData[] = [];
    series.forEach((dataSource) => {
      const candleChart: ApexChartCandleData[] = [];
      const volumeChart: ApexChartLineData[] = [];
      const lineChart: ApexChartLineData[] = [];

      dataSource.data.forEach((d, i) => {
        if (i < spliceLeft) return;

        if ("volume" in d) {
          candleChart.push([d.ts, d.open, d.high, d.low, d.close]);
          volumeChart.push([d.ts, d.volume]);
        } else {
          lineChart.push([d.ts, d.value]);
        }
      });

      if (candleChart.length) {
        seriesData.push({
          name: dataSource.name,
          type: "candlestick",
          data: candleChart,
        });
      }
      if (volumeChart.length) {
        seriesData.push({
          name: `volume_${dataSource.name}`,
          type: "bar",
          color: "#8481DD66",
          data: volumeChart,
        });
      }
      if (lineChart.length) {
        seriesData.push({
          name: dataSource.name,
          type: "line",
          color: dataSource.color,
          data: lineChart,
        });
      }
    });

    // yaxis calculation
    const yaxis = ((): { min?: number; max?: number } => {
      const candleData = seriesData.find((x) => x.type === "candlestick");

      if (!candleData) return {};

      const lowData = candleData.data.map(
        ([ts, open, high, low, close]) => low ?? Infinity,
      );
      const lowestPoint = Math.min(...lowData);

      const highData = candleData.data.map(
        ([ts, open, high, low, close]) => high ?? 0,
      );
      const highestPoint = Math.max(...highData);

      const offset = (highestPoint - lowestPoint) * VERTICAL_CHART_OFFSET;

      return {
        min: lowestPoint - offset,
        max: highestPoint + offset,
      };
    })();

    // volume chart calculations
    (() => {
      const volumeChart = seriesData.find((sd) => sd.type === "bar");
      if (!volumeChart) return;

      const maxVolume = Math.max(
        ...volumeChart.data.map(([ts, value]) => value),
      );

      const yMax = yaxis.max ?? 1;
      const yMin = yaxis.min ?? 1;

      volumeChart.data.forEach((x) => {
        x[1] =
          (x[1] / maxVolume) * ((yMax - yMin) * VOLUME_CHART_HEIGHT) + yMin;
      });
    })();

    return {
      series: seriesData,
      yaxis: {
        ...yaxis,
        labels: {
          formatter: (value: number) => value.toFixed(0),
        },
      },
    };
  }, [series, spliceLeft]);

export const useShapeOptions = (shapes: ShapeChartProps[]): ApexOptions => {
  const { mode } = useColorScheme();
  const isDarkTheme = mode === "dark";

  return useMemo(
    () => ({
      annotations: {
        points: shapes.map((s) => {
          const shape = SHAPES[s.type];
          return {
            x: s.x,
            y: s.y,
            marker: {
              size: 0,
            },
            image: {
              path: `data:image/svg+xml;base64,${shape.data[isDarkTheme ? "dark" : "light"]}`,
              width: shape.size,
              height: shape.size,
              offsetX: 0,
              offsetY: shape.offsetY,
            },
          };
        }),
      },
    }),
    [isDarkTheme, shapes],
  );
};
