import { Option, Select } from "@mui/joy";
import { Timeframe } from "../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { PERIOD } from "../Page/TestPage/test.utils";

const OPTIONS: Timeframe[] = Object.keys(PERIOD) as Timeframe[];

type Props = {
  value: Timeframe | null;
  onSelect: (value: Timeframe | null) => void;
  variant?: React.ComponentProps<typeof Select>["variant"];
};

export default function TimeframeSelect(props: Props) {
  return (
    <Select
      sx={{ width: 100 }}
      value={props.value}
      onChange={(e, v) => props.onSelect(v)}
      placeholder="TF"
      variant={props.variant || "plain"}
    >
      <Option value={null} color={props.value === null ? "primary" : "neutral"}>
        Unset
      </Option>
      {OPTIONS.map((option) => (
        <Option
          key={option}
          value={option}
          color={props.value === option ? "primary" : "neutral"}
        >
          {option.toUpperCase()}
        </Option>
      ))}
    </Select>
  );
}
