import { useState } from "react";
import { Button, ButtonGroup, Card, IconButton } from "@mui/joy";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const DEFAULT_VALUE = 1;
const OPTIONS = [0.25, 0.5, 0.75, 1, 2, 3, 4, 6, 8];

type Props = {
  onChange: (value: number) => void;
};

export default function Zoom({ onChange }: Props) {
  const [value, setValue] = useState(DEFAULT_VALUE);
  return (
    <Card variant="plain" sx={{ p: 0 }}>
      <ButtonGroup sx={{ height: "100%" }}>
        <IconButton
          onClick={() => {
            const index = OPTIONS.indexOf(value);
            const nextIndex = Math.max(index - 1, 0);
            const nextValue = OPTIONS[nextIndex];
            setValue(nextValue);
            onChange(nextValue);
          }}
        >
          <RemoveIcon />
        </IconButton>
        <Button onClick={() => onChange(DEFAULT_VALUE)}>
          {(value * 100).toFixed(0)}%
        </Button>
        <IconButton
          onClick={() => {
            const index = OPTIONS.indexOf(value);
            const nextIndex = Math.min(index + 1, OPTIONS.length - 1);
            const nextValue = OPTIONS[nextIndex];
            setValue(nextValue);
            onChange(nextValue);
          }}
        >
          <AddIcon />
        </IconButton>
      </ButtonGroup>
    </Card>
  );
}
