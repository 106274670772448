import { Box, Button, Card, Typography } from "@mui/joy";
import { MA_VALUES_PAIRS } from "./test.utils";
import { useAddTaskMutation } from "../../api/backtest.api";
import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { BEST_COINS } from "../../const";
import MarketIcon from "../../Components/MarketIcon";
import { formatNumber, getRandomArrayElements } from "../../utils";

const MA_VALUES: [symbol: string, tf: Timeframe, a: number, b: number][] = [];

BEST_COINS.forEach((coin) => {
  const tfArr = Object.keys(MA_VALUES_PAIRS) as Timeframe[];
  tfArr.forEach((tf) => {
    const vp = MA_VALUES_PAIRS[tf];
    vp?.forEach(([a, b]) => {
      MA_VALUES.push([`${coin}USDT`, tf, a, b]);
    });
  });
});

export default function SuperMultiStart() {
  const addTaskMutation = useAddTaskMutation();

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          size="lg"
          onClick={() => {
            MA_VALUES.forEach(([symbol, tf, smaA, smaB]) => {
              addTaskMutation.mutate({
                symbol,
                tf,
                smaA,
                smaB,
              });
            });
          }}
          loading={addTaskMutation.isPending}
        >
          Start '{formatNumber(MA_VALUES.length)}' tasks
        </Button>
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {getRandomArrayElements(MA_VALUES, 500).map(([symbol, tf, a, b], i) => (
          <Card key={i} sx={{ p: 1, alignItems: "center" }} size="sm">
            <MarketIcon symbol={symbol} />
            <Typography level="body-xs">
              {tf} {a} x {b}
            </Typography>
          </Card>
        ))}
      </Box>
    </>
  );
}
