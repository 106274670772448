import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { backtestController } from "./rpc.client";
import { Timeframe } from "../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { MS_IN_DAY } from "../timeframe";
import { PERIOD } from "../Page/TestPage/test.utils";

const TASK_LIST_QUERY_KEY = "taskList";

export const useTaskList = () =>
  useSuspenseQuery({
    queryKey: [TASK_LIST_QUERY_KEY],
    queryFn: () => backtestController.getBacktestTasks({}),
    refetchInterval: 15000,
  });

export const useAnalysisList = (symbol?: string, tf?: Timeframe) => {
  return useQuery({
    queryKey: ["task", "analysis", symbol, tf],
    queryFn: () => backtestController.getAnalytics({ limit: 300, symbol, tf }),
    refetchInterval: 60000,
  });
};

type AddTaskProps = {
  symbol: string;
  tf: Timeframe;
  smaA: number;
  smaB: number;
};

export const useAddTaskMutation = () => {
  const queryClient = useQueryClient();
  const today = Math.floor(Date.now() / MS_IN_DAY) * MS_IN_DAY;
  return useMutation({
    mutationFn: ({ symbol, tf, smaA, smaB }: AddTaskProps) =>
      backtestController.addBacktestTask({
        symbol: symbol,
        timeframe: tf,
        tsStart: today - (PERIOD[tf] ?? 0),
        tsEnd: today,
        strategyId: 0,
        smaIntervalA: smaA,
        smaIntervalB: smaB,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TASK_LIST_QUERY_KEY] });
    },
  });
};

export const useClearAllTask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: backtestController.clearAll,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TASK_LIST_QUERY_KEY] });
    },
  });
};

export const useBacktestOrder = () => {
  const params = useParams();

  const orderId = useMemo(() => {
    const result = Number(params.order_id);

    return isNaN(result) ? undefined : result;
  }, [params.order_id]);

  return useSuspenseQuery({
    queryKey: ["backtest", "order", orderId],
    queryFn: async () => {
      if (!orderId) return;
      return await backtestController.getBacktestOrder(orderId);
    },
  });
};

export const useBacktestTask = (taskId: number) =>
  useSuspenseQuery({
    queryKey: ["backtest", "task", taskId],
    queryFn: async () => await backtestController.getBacktestTask(taskId),
  });

export const useDashboard = () =>
  useQuery({
    queryKey: ["dashboard"],
    queryFn: async () => await backtestController.dashboard(),
    refetchInterval: 30000,
  });
