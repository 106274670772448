import { Button, LinearProgress, Typography } from "@mui/joy";
import { useDashboard } from "../../api/backtest.api";
import SimplePageLayout from "../../Layout/SimplePageLayout";
import { useMemo } from "react";
import MaXMaAnalyticsTable from "./MaXMaAnalyticsTable";
import { useNavigateAnalyticsListPage } from "./maxma.utils";

export default function MaXMaDashboardPage() {
  const { data, isLoading } = useDashboard();
  const progress = useMemo(() => {
    if (!data) return;
    return (data.progress.completed / data.progress.total) * 100;
  }, [data]);

  const navigateAnalytics = useNavigateAnalyticsListPage();

  return (
    <SimplePageLayout title="Dashboard" isLoading={isLoading}>
      {progress && (
        <LinearProgress
          determinate
          variant="outlined"
          size="sm"
          thickness={24}
          value={progress}
        >
          <Typography
            level="body-xs"
            textColor="common.white"
            sx={{ fontWeight: "xl", mixBlendMode: "difference" }}
          >
            {`Completed: ${data?.progress.completed}/${data?.progress.total} (${Math.round(progress)}%)`}
          </Typography>
        </LinearProgress>
      )}
      <Typography level="h2" textAlign={"center"} sx={{ mt: 2 }}>
        TOP-5
      </Typography>
      {data && <MaXMaAnalyticsTable rows={data.topTasks} />}
      <Button
        variant="plain"
        sx={{ width: "100%" }}
        onClick={navigateAnalytics}
      >
        ALL
      </Button>
    </SimplePageLayout>
  );
}
