import { generatePath, useNavigate } from "react-router-dom";
import { Typography } from "@mui/joy";

import { useBacktestOrder } from "../../api/backtest.api";
import OrderChart from "../../Components/Chart/OrderChart";
import { useRoute } from "../../Layout/router.const";
import SimplePageLayout from "../../Layout/SimplePageLayout";
import { generateTaskName } from "./maxma.utils";
import LeftRightButtons from "../../Components/LeftRightButtons";
import MaXMaOrderConclusion from "./MaXMaOrderConclusion";

export default function MaXMaOrderPage() {
  const navigate = useNavigate();

  const parentPage = useRoute("MA x MA - Analysis");
  const taskPage = useRoute("MA x MA - Task");
  const orderPage = useRoute("MA x MA - Order");

  const { data } = useBacktestOrder();

  if (!data) return <Typography>404</Typography>;

  const breadcrumbs = [{ text: parentPage.name, href: parentPage.path }];

  breadcrumbs.push({
    text: generateTaskName(data.task),
    href: generatePath(taskPage.path, { task_id: data?.task.id }),
  });

  // TODO: ограничить переход в ордера соседних тасков

  const goToOrder = (val: number) => () =>
    navigate(generatePath(orderPage.path, { order_id: data.order.id + val }));

  return (
    <SimplePageLayout
      title={`Order (ID: ${data?.order.id})`}
      breadcrumbs={breadcrumbs}
      contentPadding={0}
      rightTitle={
        <LeftRightButtons
          onLeftButtonClick={goToOrder(-1)}
          onRightButtonClick={goToOrder(1)}
        />
      }
      bottomTitle={<MaXMaOrderConclusion order={data.order} />}
    >
      {data && (
        <OrderChart
          symbol={data.task.symbol}
          tf={data.task.timeframe}
          type={data.order.type}
          enter={{ ts: data.order.tsOpen, price: data.order.entryPrice }}
          exit={{ ts: data.order.tsClose, price: data.order.exitPrice }}
          sma={{ a: data.task.smaIntervalA, b: data.task.smaIntervalB }}
        />
      )}
    </SimplePageLayout>
  );
}
