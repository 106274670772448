import { Box, Typography } from "@mui/joy";
import {
  BacktestTaskAnalysisAltEntity,
  BacktestTaskEntity,
} from "../../../../crypto-bulls-backend/src/components/backtest/backtest.entity";
import { formatNumber } from "../../utils";

type Props = {
  task: BacktestTaskEntity;
  analytics: BacktestTaskAnalysisAltEntity;
};

export default function MaXMaTaskConclusion({ analytics }: Props) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography level="body-sm">Margin: {analytics.margin}</Typography>
        <Typography level="body-sm">
          StopLoss: {((1 - analytics.stopLoss) * 100).toFixed(2)}%
        </Typography>
        <Typography level="body-sm">
          Pool: {(analytics.pool * 100).toFixed(2)}%
        </Typography>
        <Typography level="body-sm">
          WinRate: {(analytics.winRate * 100).toFixed(2)}%
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Typography level="body-sm" fontWeight={600} color="success">
          {`Avg profit: ${formatNumber((analytics.absoluteAvgYearProfit * 100).toFixed(2))} %`}
        </Typography>
      </Box>
    </Box>
  );
}
