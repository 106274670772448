import { FC, useMemo } from "react";

import HomePage from "../Page/HomePage";
import SettingsPage from "../Page/SettingsPage";
import StrategyPage from "../Page/StrategyPage/StrategyPage";
import TestPage from "../Page/TestPage/TestPage";
import UsersPage from "../Page/UsersPage/UsersPage";
import WalletsPage from "../Page/WalletsPage";
import MaXMaTaskPage from "../Page/MaXMa/MaXMaTaskPage";
import MaXMaOrderPage from "../Page/MaXMa/MaXMaOrderPage";
import MaXMaTaskChartPage from "../Page/MaXMa/MaXMaTaskChartPage";
import MaXMaAnalysisPage from "../Page/MaXMa/MaXMaAnalysisPage";
import MaXMaDashboardPage from "../Page/MaXMa/MaXMaDashboardPage";

type Route = {
  name: string;
  redirect?: string;
  path: string;
  element: FC;
};

export const routes: Route[] = [
  {
    name: "home",
    path: "/",
    redirect: "/maxma/",
    element: HomePage,
  },
  {
    name: "strategy",
    path: "/strategy/:id",
    element: StrategyPage,
  },
  {
    name: "MA x MA - Dashboard",
    path: "/maxma/",
    element: MaXMaDashboardPage,
  },
  {
    name: "MA x MA - Analysis",
    path: "/maxma/analytics",
    element: MaXMaAnalysisPage,
  },
  {
    name: "MA x MA - Task",
    path: "/maxma/task/:task_id",
    element: MaXMaTaskPage,
  },
  {
    name: "MA x MA - Chart",
    path: "/maxma/task/:task_id/chart",
    element: MaXMaTaskChartPage,
  },
  {
    name: "MA x MA - Order",
    path: "/maxma/order/:order_id",
    element: MaXMaOrderPage,
  },
  {
    name: "settings",
    path: "/settings",
    element: SettingsPage,
  },
  {
    name: "users",
    path: "/users",
    element: UsersPage,
  },
  {
    name: "wallets",
    path: "/wallets",
    element: WalletsPage,
  },
  {
    name: "test",
    path: "/test",
    element: TestPage,
  },
];

const getRouteByName = (name: string) => {
  const result = routes.find((r) => r.name === name);
  if (!result) throw Error();
  return result;
};

export const useRoute = (name: string) =>
  useMemo(() => getRouteByName(name), [name]);
