import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from "@mui/joy";
import SimplePageLayout from "../../Layout/SimplePageLayout";
import {
  useAddTaskMutation,
  useClearAllTask,
  useTaskList,
} from "../../api/backtest.api";
import { useState } from "react";
import MarketSelect from "../../Components/MarketSelect";
import { fixJsFloat } from "../../utils";
import { generatePath, useNavigate } from "react-router-dom";
import { useRoute } from "../../Layout/router.const";
import Multistart from "./Multistart";
import SuperMultiStart from "./SuperMultiStart";

export default function TestPage() {
  const navigate = useNavigate();
  const taskRoute = useRoute("MA x MA - Task");
  const backtestList = useTaskList();

  const addTaskMutation = useAddTaskMutation();
  const clearAll = useClearAllTask();

  const [symbol, setSymbol] = useState<string | null>(null);
  const [a, setA] = useState(7);
  const [b, setB] = useState(28);

  return (
    <SimplePageLayout title="Test">
      <Tabs>
        <TabList>
          <Tab>Start</Tab>
          <Tab>MultiStart</Tab>
          <Tab>SuperMultiStart</Tab>
          <Tab color="danger">Delete</Tab>
        </TabList>
        <TabPanel value={0}>
          <Sheet sx={{ p: 2 }}>
            <Box>
              <MarketSelect
                variant="outlined"
                value={symbol}
                onSelect={setSymbol}
              />
            </Box>
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              <Input
                type="number"
                value={a}
                onChange={(e) => setA(+e.target.value)}
              />
              <Input
                type="number"
                value={b}
                onChange={(e) => setB(+e.target.value)}
              />
              <Button
                disabled={!symbol}
                color={addTaskMutation.error ? "danger" : "primary"}
                onClick={() =>
                  addTaskMutation.mutate({
                    symbol: symbol ?? "",
                    tf: "1d",
                    smaA: a,
                    smaB: b,
                  })
                }
              >
                {"Go!"}
              </Button>
            </Box>
          </Sheet>
          <Sheet sx={{ p: 2 }}>
            <List>
              {backtestList.data.map((task) => (
                <ListItem key={task.id}>
                  <ListItemButton
                    disabled={task.status !== "completed"}
                    onClick={() =>
                      navigate(
                        generatePath(taskRoute.path, { task_id: task.id }),
                      )
                    }
                  >
                    <ListItemContent>
                      {`(ID: ${task.id}) ${task.symbol} ${task.timeframe} ${task.smaIntervalA}x${task.smaIntervalB} profit(${fixJsFloat(task.profit)})`}
                    </ListItemContent>
                    <ListItemDecorator>
                      {fixJsFloat(
                        Math.round(
                          (((task.ts || task.tsStart) - task.tsStart) /
                            (task.tsEnd - task.tsStart)) *
                            1000,
                        ) / 10,
                      )}{" "}
                      %
                    </ListItemDecorator>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Sheet>
        </TabPanel>
        <TabPanel value={1}>
          <Multistart />
        </TabPanel>
        <TabPanel value={2}>
          <SuperMultiStart />
        </TabPanel>
        <TabPanel value={3}>
          <Button
            color="danger"
            onClick={() => clearAll.mutate()}
            loading={clearAll.isPending}
          >
            DELETE ALL
          </Button>
        </TabPanel>
      </Tabs>
    </SimplePageLayout>
  );
}
