import { Box, IconButton, Table } from "@mui/joy";

import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ViewListIcon from "@mui/icons-material/ViewList";

import MarketIcon from "../../Components/MarketIcon";
import { fixJsFloat, separateNumber } from "../../utils";
import { TAnalysisResponseItem } from "../../../../crypto-bulls-backend/src/components/backtest/backtest.type";
import { useNavigateTaskChartPage, useNavigateTaskPage } from "./maxma.utils";

type Props = {
  rows: TAnalysisResponseItem[];
};

export default function MaXMaAnalyticsTable(props: Props) {
  const navigateTaskPage = useNavigateTaskPage();
  const navigateTaskChartPage = useNavigateTaskChartPage();

  return (
    <Table hoverRow>
      <thead>
        <tr>
          <th style={{ width: 75 }}>task ID</th>
          <th style={{ width: 75 }}>Market</th>
          <th style={{ width: 50 }}>TF</th>
          <th style={{ width: 75 }}>sma(A)</th>
          <th style={{ width: 75 }}>sma(B)</th>
          <th style={{ width: 100 }}>Margin</th>
          <th style={{ width: 100 }}>SL</th>
          <th style={{ width: 100 }}>Pool</th>
          <th style={{ width: 100 }}>WinRate</th>
          <th style={{ width: 100 }}>Profit</th>
          <th style={{ width: 100 }}>AVG Profit</th>
          <th style={{ width: 100, textAlign: "center" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.rows.map((analysis) => (
          <tr key={analysis.taskId}>
            <td>{analysis.taskId}</td>
            <td>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <MarketIcon symbol={analysis.symbol} />
              </Box>
            </td>
            <td>{analysis.tf}</td>
            <td>{analysis.a}</td>
            <td>{analysis.b}</td>
            <td>{analysis.margin}</td>
            <td>{fixJsFloat((1 - analysis.stopLoss) * 100)} %</td>
            <td>{fixJsFloat(analysis.pool * 100)} %</td>
            <td>{fixJsFloat(analysis.winRate * 100)} %</td>
            <td>{fixJsFloat(analysis.profit * 100)} %</td>
            <td>
              {separateNumber(fixJsFloat(analysis.yearAvgProfit * 100))} %
            </td>
            <td style={{ textAlign: "center" }}>
              <IconButton onClick={() => navigateTaskPage(analysis.taskId)}>
                <ViewListIcon />
              </IconButton>
              <IconButton
                onClick={() => navigateTaskChartPage(analysis.taskId)}
              >
                <QueryStatsIcon />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
