import { Typography } from "@mui/joy";
import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { useBacktestTask } from "../../api/backtest.api";
import { useMarketHistory } from "../../api/binance.spot.api";
import Chart from "./Chart";
import Spinner from "../Spinner";
import { sma } from "../../indicator";
import { useCandleData } from "./hooks";
import { MS_IN_TIMEFRAME } from "../../timeframe";

const KLINE_MAX_COUNT = 2900;

type Props = {
  taskId: number;
};

export default function TaskChart(props: Props) {
  const {
    data: { task, orders },
  } = useBacktestTask(props.taskId);

  const maxSma = Math.max(task.smaIntervalA, task.smaIntervalB);
  const tfMs = MS_IN_TIMEFRAME[task.timeframe];

  const startTs = task.tsStart - maxSma * tfMs;
  const startTsThreshold = task.tsEnd - KLINE_MAX_COUNT * tfMs;

  const { data, isLoading, isError, error } = useMarketHistory(
    task.symbol,
    task.timeframe as Timeframe,
    Math.max(startTs, startTsThreshold),
    task.tsEnd,
  );

  const { candleData, lineData } = useCandleData(data || []);

  if (isLoading || !data) {
    return <Spinner />;
  }

  if (isError) {
    return <Typography>{error.message}</Typography>;
  }

  return (
    <Chart
      spliceLeft={maxSma}
      series={[
        { name: task.symbol, data: candleData },

        {
          name: `sma(${task.smaIntervalA})`,
          color: "#F8AE54",
          data: sma(lineData, task.smaIntervalA),
        },
        {
          name: `sma(${task.smaIntervalB})`,
          color: "#92C5F9",
          data: sma(lineData, task.smaIntervalB),
        },
      ]}
      shapes={orders.map((order) => ({
        type: order.type === "long" ? "up" : "down",
        x: order.tsOpen,
        y: order.entryPrice,
      }))}
    />
  );
}
