import { IconButton } from "@mui/joy";
import { useParams } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import SimplePageLayout from "../../Layout/SimplePageLayout";
import TaskChart from "../../Components/Chart/TaskChart";
import { generateTaskName, useNavigateTaskPage } from "./maxma.utils";
import { useBacktestTask } from "../../api/backtest.api";
import { useRoute } from "../../Layout/router.const";
import MaXMaTaskConclusion from "./MaXMaTaskConclusion";

export default function MaXMaTaskChartPage() {
  const { task_id } = useParams();
  const taskId = +(task_id || -1);

  const parentPage = useRoute("MA x MA - Analysis");

  const {
    data: { task, analytics },
  } = useBacktestTask(taskId);

  const navigateTaskPage = useNavigateTaskPage();

  return (
    <SimplePageLayout
      title={generateTaskName(task)}
      breadcrumbs={[{ text: parentPage.name, href: parentPage.path }]}
      contentPadding={0}
      rightTitle={
        <IconButton onClick={() => navigateTaskPage(task.id)}>
          <ViewListIcon />
        </IconButton>
      }
      bottomTitle={<MaXMaTaskConclusion task={task} analytics={analytics} />}
    >
      <TaskChart taskId={taskId} />
    </SimplePageLayout>
  );
}
