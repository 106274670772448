import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";

export const COMPANY_LOGO = CurrencyBitcoinIcon;
export const COMPANY_NAME = "CryptoBulls";

export const LS_AUTH_KEY = "authkey";

export const API_URL =
  process.env.REACT_APP_BACKEND_URL || "https://api.cryptobulls.dev";

export const COLOR = {
  A: "#003f5c",
  B: "#58508d",
  C: "#bc5090",
  D: "#ff6361",
  E: "#ffa600",
};

export const NEW_LINE = `
`;

export const BEST_COINS = [
  "BTC",
  "ETH",
  "BNB",
  "SOL",
  "XRP",
  "DOGE",
  "TRX",
  "ADA",
  "AVAX",
  "XLM",
  "TON",
  "DOT",
  "XLM",
  "LINK",
  "BCH",
  "SUI",
  "PEPE",
  "NEAR",
  "UNI",
];
