import { ButtonGroup, IconButton } from "@mui/joy";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
};

export default function LeftRightButtons(props: Props) {
  return (
    <ButtonGroup>
      <IconButton onClick={props.onLeftButtonClick}>
        <ChevronLeftIcon />
      </IconButton>
      <IconButton onClick={props.onRightButtonClick}>
        <ChevronRightIcon />
      </IconButton>
    </ButtonGroup>
  );
}
