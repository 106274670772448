import { Box, Typography } from "@mui/joy";

import SimplePageLayout from "../../Layout/SimplePageLayout";
import { useAnalysisList } from "../../api/backtest.api";
import MarketSelect from "../../Components/MarketSelect";
import { useState } from "react";
import TimeframeSelect from "../../Components/TimeframeSelect";
import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";
import MaXMaAnalyticsTable from "./MaXMaAnalyticsTable";

export default function MaXMaAnalysisPage() {
  const [symbol, setSymbol] = useState<string | null>(null);
  const [tf, setTf] = useState<Timeframe | null>(null);

  const { data, isLoading } = useAnalysisList(
    symbol ?? undefined,
    tf ?? undefined,
  );

  return (
    <SimplePageLayout title="MA x MA - Analysis" isLoading={isLoading}>
      <Box sx={{ mb: 2, display: "flex", alignItems: "center", gap: 2 }}>
        <Typography>Filter:</Typography>
        <TimeframeSelect value={tf} onSelect={setTf} />
        <MarketSelect value={symbol} onSelect={setSymbol} />
      </Box>
      {data && <MaXMaAnalyticsTable rows={data.list} />}
    </SimplePageLayout>
  );
}
