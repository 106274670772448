import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";
import { MS_IN_DAY } from "../../timeframe";
import { PartialRecord } from "../../utils";

export const PERIOD: PartialRecord<Timeframe, number> = {
  "1m": MS_IN_DAY * 7,
  "1h": MS_IN_DAY * 365 * 1.5,
  "8h": MS_IN_DAY * 365 * 2.5,
  "1d": MS_IN_DAY * 365 * 5,
};

export const MA_VALUES_PAIRS: PartialRecord<Timeframe, [number, number][]> = {
  "1m": [],
  "1h": [],
  "8h": [],
  "1d": [],
};

const generate =
  (target?: [number, number][]) => (a: number, i: number, arr: number[]) => {
    if (!target) return;
    const values = arr.slice(i).map((b) => [a, b] as [number, number]);
    values.shift();
    target.push(...values);
  };

[1, 2, 3, 4, 5, 6, 7, 10, 14, 20, 25, 28, 60, 90, 120, 150, 180, 365].forEach(
  generate(MA_VALUES_PAIRS["1d"]),
);

[1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 40, 50, 75, 100, 125, 150].forEach(
  generate(MA_VALUES_PAIRS["8h"]),
);

[
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  10,
  12,
  15,
  18,
  20,
  24,
  36,
  24 * 2,
  24 * 3,
  24 * 5,
  24 * 7,
  24 * 10,
  24 * 14,
  24 * 28,
  24 * 60,
].forEach(generate(MA_VALUES_PAIRS["1h"]));

[1, 3, 5, 10, 15, 20, 25, 30, 45, 60, 90, 120, 150, 180, 240].forEach(
  generate(MA_VALUES_PAIRS["1m"]),
);
