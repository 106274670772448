import { useMemo } from "react";
import { Box, Option, Select, Typography } from "@mui/joy";

import { Market } from "../../../crypto-bulls-backend/src/components/market/market.type";
import { useMarkets } from "../api/binance.spot.api";
import { BEST_COINS } from "../const";
import MarketIcon from "./MarketIcon";
import Spinner from "./Spinner";

const marketFilter = (market: Market) =>
  market.quoteAsset === "USDT" && BEST_COINS.includes(market.baseAsset || "");

type Props = {
  value: string | null;
  onSelect: (value: string | null) => void;
  variant?: React.ComponentProps<typeof Select>["variant"];
};

export default function MarketSelect(props: Props) {
  const markets = useMarkets();
  const bestMarkets = useMemo(
    () => (markets.data || []).filter(marketFilter),
    [markets.data],
  );

  return (
    <Select
      sx={{ width: 300, minHeight: 38 }}
      placeholder="Symbol"
      variant={props.variant || "plain"}
      value={props.value}
      color={markets.isError ? "danger" : "neutral"}
      onChange={(e, v) => props.onSelect(v)}
      endDecorator={props.value && <MarketIcon symbol={props.value} />}
    >
      {markets.isLoading && (
        <Box sx={{ p: 4 }}>
          <Spinner size="md" />
        </Box>
      )}
      {!markets.isLoading && (
        <Option
          value={null}
          color={props.value === null ? "primary" : "neutral"}
        >
          Unset
        </Option>
      )}
      {!markets.isLoading &&
        !markets.isError &&
        bestMarkets.map((market) => (
          <Option
            key={market.id}
            value={market.symbol}
            color={props.value === market.symbol ? "primary" : "neutral"}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontWeight="lg">{market.symbol}</Typography>
              {market.baseAsset && market.quoteAsset && (
                <MarketIcon
                  baseAsset={market.baseAsset}
                  quoteAsset={market.quoteAsset}
                  size="md"
                />
              )}
            </Box>
          </Option>
        ))}
    </Select>
  );
}
