import { useState } from "react";
import { Box, Button, Card, Typography } from "@mui/joy";
import { MA_VALUES_PAIRS } from "./test.utils";
import { useAddTaskMutation } from "../../api/backtest.api";
import MarketSelect from "../../Components/MarketSelect";
import TimeframeSelect from "../../Components/TimeframeSelect";
import { Timeframe } from "../../../../crypto-bulls-backend/src/components/gateway/timeframe";

export default function Multistart() {
  const addTaskMutation = useAddTaskMutation();

  const [symbol, setSymbol] = useState<string | null>(null);
  const [tf, setTf] = useState<Timeframe | null>(null);

  const maValues = (tf && MA_VALUES_PAIRS[tf]) || [];

  return (
    <>
      <Box sx={{ display: "flex", gap: 1 }}>
        <MarketSelect value={symbol} onSelect={setSymbol} />
        <TimeframeSelect value={tf} onSelect={setTf} />
        <Button
          disabled={!symbol || !tf}
          onClick={() => {
            if (!tf) return;
            maValues.forEach(([smaA, smaB]) => {
              addTaskMutation.mutate({
                smaA,
                smaB,
                symbol: symbol ?? "",
                tf,
              });
            });
          }}
          loading={addTaskMutation.isPending}
        >
          Start
        </Button>
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {tf &&
          maValues.map((v, i) => (
            <Card key={i} sx={{ p: 1 }} size="sm">
              <Typography level="body-xs">
                {v[0]} x {v[1]}
              </Typography>
            </Card>
          ))}
      </Box>
    </>
  );
}
